// Copyright © 2021 Move Closer

import { AnyModule, LayoutContainer, LayoutContainerType, ModuleContent, NewsBarContent } from '@d24/modules'
import { AnyObject, IModel, MagicModel } from '@movecloser/front-core'

import { HasIdentifier, Identifier } from '@/shared/contracts/data'

import { AuthorModel, ContentType } from '@module/content/contracts'

/**
 * Defines possible states of settings
 *
 * @author Javlon khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type GeneralSettingOption = true | false | null | string

/**
 * List of all GeneralSettings
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@moveloser.pl>
 */
export enum GeneralSettings {
  Ads = 'ads',
  AllowComments = 'allowComments',
  LockCommenting = 'lockCommenting',
  HideAuthor = 'hideAuthor',
  HidePublicationDate = 'hidePublicationDate',
  Paywall = 'paywall'
}

/**
 * Payload of General Settings
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export interface GeneralSettingsData {
  // Settings for article
  [GeneralSettings.Ads]: GeneralSettingOption
  [GeneralSettings.AllowComments]: GeneralSettingOption
  [GeneralSettings.LockCommenting]: GeneralSettingOption
  [GeneralSettings.HideAuthor]: GeneralSettingOption
  [GeneralSettings.HidePublicationDate]: GeneralSettingOption
  [GeneralSettings.Paywall]: GeneralSettingOption
  // Settings for document
  noIndexNoFollow: GeneralSettingOption
  hideSubscriptionButton: GeneralSettingOption
  premiumLink: string
  hjId: GeneralSettingOption
  // Paywall options
  paywallOptions: AnyObject

}

export type LayoutContainers = Record<LayoutContainer, LayoutContainerType>

export enum LayoutSlot {
  After = 'after',
  Before = 'before',
  Bottom = 'bottom',
  Comments = 'comments',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export type LayoutSlotData = {
  [name in LayoutSlot]: AnyModule[]
}

export type LayoutAddonData = {
  [type in ContentType]?: { id: Identifier; name: string }
}

export interface LayoutData extends HasIdentifier {
  containers: LayoutContainers
  createdAt?: string
  editor?: AuthorModel
  isActive: boolean
  name: string
  slots: LayoutSlotData[]
  updatedAt?: string
}

export type ILayout = IModel<LayoutData>

export interface InfoBarData extends NewsBarContent {
  isActive: boolean
}

export interface AdsConfigData extends ModuleContent {
  wtgLink?: string
  wtgDelay?: number
  taboolaDelay?: number
  inText?: string
  useNewWTG?: boolean
  adsFileContent?: string
  overrides?: Overrides
  default?: AdsConfig
  stickyAd?: StickyConfig
}

export interface Overrides {
  user: AdsOverride
  none: AdsOverride
  paid: AdsOverride
}

export interface VideoSettings {
  youtube: number
  hls: number
  on: number
}

export interface StickyConfig {
  premium: boolean
  sponsored: boolean
  restrictedUrls: string
}

export interface AdsConfig {
  wtg: boolean
  taboola: boolean
  onNetwork: boolean
  openMarket: boolean
  ads: boolean
  autoHide: boolean
  doNotShrinkCount: number
  forceD24Ads: boolean
  forceD24AdsOnWTG: boolean
  forceD24AdsOnSticky: boolean
  forceD24AdsOnCover: boolean
  forceD24AdsOnOnNetwork: boolean
  stickyAd: boolean
  stickyAdTeaser: boolean
  stickyAdTeaserVideo: boolean
  adReloadAll: boolean
  videoSettings: VideoSettings
}

export interface AdsOverride {
  wtg: boolean | null
  taboola: boolean | null
  onNetwork: boolean | null
  openMarket: boolean | null
  ads: boolean | null
  autoHide: boolean | null
  doNotShrinkCount: number | null
  forceD24Ads: boolean | null
  forceD24AdsOnWTG: boolean | null
  forceD24AdsOnSticky: boolean | null
  forceD24AdsOnCover: boolean | null
  forceD24AdsOnOnNetwork: boolean | null
  stickyAd: boolean | null
  stickyAdTeaser: boolean | null
  stickyAdTeaserVideo: boolean | null
  adReloadAll: boolean | null
  videoSettings: VideoSettings | null
}

export interface CouponData extends HasIdentifier {
  code: string
  isUsed: boolean
  transactionId?: Identifier
  userId?: Identifier
  promotionId: Identifier
  usedAt?: string
}

export type ICoupon = IModel<CouponData>

export type LayoutModel = MagicModel<LayoutData, ILayout>

export interface NavigationData extends HasIdentifier {
  driver: NavigationDriver
  from: Identifier | null
  isActive: boolean
  links?: NavigationItem[]
  name: string
  slot: string
}

export enum NavigationDriver {
  Content = 'content',
  Manual = 'manual'
}

export interface NavigationItem {
  children: NavigationItem[]
  icon?: string
  label: string
  strategy: OpenStrategy
  hideInNav?: boolean
  target: NavigationTarget | null
}

export interface NavigationTarget {
  link: string
  type: Target
}

export enum OpenStrategy {
  Blank = 'blank',
  Self = 'self'
}

export interface Price {
  monthly: number
  annual: number
  'semi-annual': number
  savesMonthly: number
  savesAnnual: number
  savesSemiAnnual: number
  fullAnnual: number
  fullSemiAnnual: number
  fullMonthly: number
}

export interface Prices {
  standard: Price
  premium: Price
}

export enum PromotionCouponType {
  Single = 'single',
  OneTime = 'one_per_transaction'
}

export enum PromotionType {
  Amount = 'amount',
  FreeTrial = 'free_trial',
  Percentage = 'percentage'
}

export enum PromotionProductType {
  Article = 'article',
  Subscription = 'subscription',
  SubscriptionMonthly = 'subscription_monthly',
  SubscriptionAnnual = 'subscription_annual',
}

export interface PromotionData extends HasIdentifier {
  codesCount?: number
  codesUsed?: number
  conditions: AnyObject
  config: AnyObject
  couponType: PromotionCouponType
  coupon: string
  endAt: string
  isActive: boolean
  name: string
  productTarget?: Identifier[]
  productType: PromotionProductType
  startAt: string
  type: PromotionType
}

export interface PromotionCouponConfig {
  length: number
  prefix?: string
  quantity: number
}

export type IPromotion = IModel<PromotionData>

export enum Target {
  External = 'external',
  Internal = 'internal'
}

export interface TagData {
  id: number
  name: string
  pluralName?: string
  slug: string
  type?: string
  deletedAt?: string | null
  count?: number
  useAsTagPage: {}
}

export interface TagPayload {
  id?: number
  name: string
  pluralName?: string
  slug: string
  type: TagType
}

export enum TagType {
  Label = 'label',
  Tag = 'tag'
}

export interface SettingData {
  type: string
  value: AnyModule | string
}

export type CouponModel = MagicModel<CouponData>

export type PromotionModel = MagicModel<PromotionData>

export type TagModel = MagicModel<TagData>

export type INavigation = IModel<NavigationData>

export type NavigationModel = MagicModel<NavigationData, INavigation>
