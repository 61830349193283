





















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@d24/modules'
import { DateTimeType, IDateTime } from '@movecloser/front-core'

import { Inject } from '@/shared/plugins/inversify'

import { FileModel, VideoVariantParams, VideoVariantStatus } from '../contracts'
import { fileSize } from '../helpers'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'FileDataTable'
})
export class FileDataTable extends Vue {
  @Prop({
    type: String,
    required: false
  })
  public variantUrl?: string | null

  @Prop({
    type: Object,
    required: true
  })
  public file!: FileModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public icons = DashmixIconName
  public fileSize = fileSize

  public get fileDirectory (): string {
    const fileArr = this.file.fullRoute().split('/')
    fileArr.pop()

    return fileArr.join('/')
  }

  public videoDetails: boolean = false

  public toggleMore () {
    this.videoDetails = !this.videoDetails
  }

  public copied = false

  public copyToClipboard (toCopy: string) {
    navigator.clipboard.writeText(toCopy).catch(e => {
      console.error(e)
    })
    this.copied = true
    setTimeout(() => {
      this.copied = false
    }, 300)
  }

  public getHLSProgress (item: VideoVariantParams): VideoVariantStatus {
    switch (true) {
      case item.push === 'finished':
        return {
          value: 100,
          status: '100%',
          label: 'Finished'
        }
      case item.m3u8 === 'finished':
        return {
          value: 75,
          status: '75%',
          label: 'M3u8 generated'
        }
      case item.thumbnail === 'finished':
        return {
          value: 50,
          status: '50%',
          label: 'Thumbnail generated'
        }
      case item.video === 'finished':
        return {
          value: 25,
          status: '25%',
          label: 'Compressed'
        }
      default:
        return {
          value: 0,
          status: '0%',
          label: 'not started'
        }
    }
  }

  public get fileVariantUrl () {
    return this.variantUrl === '' ? this.file.url : this.variantUrl
  }

  public get directoryLink () {
    return {
      name: 'media.showDirectory',
      params: { id: this.file.directory.id.toString() }
    }
  }
}

export default FileDataTable
