// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector,
  IModal,
  Interfaces,
  ModalType,
  Module
} from '@movecloser/front-core'

import { ContentRepository } from './repositories/content'
import {
  AcceptanceRepositoryType,
  CommentRepositoryType,
  ContentRepositoryType,
  FeedEntriesRepositoryType,
  FeedsRepositoryType,
  IAcceptanceRepository,
  ICommentRepository,
  IContentRepository,
  IFeedEntriesRepository,
  IFeedsRepository,
  INodesRepository,
  IRegistryRepository,
  ISetItemsRepository,
  ISetsRepository,
  IVariantsRepository,
  NodesRepositoryType,
  RegistryRepositoryType,
  SetItemsRepositoryType,
  SetsRepositoryType,
  VariantsRepositoryType
} from './contracts/repositories'

import { AcceptanceRepository } from './repositories/acceptance'
import { CommentRepository } from './repositories/comments'
import { FeedsRepository } from './repositories/feeds'
import { FeedEntriesRepository } from './repositories/feedEntries'
import { IVariantStatusService, VariantStatusService, VariantStatusServiceType } from './services/variantStatus'
import { NodesRepository } from './repositories/nodes'
import { resources } from './resources'
import { routesFactory } from './routes'
import { SetItemsRepository } from './repositories/setItems'
import { SetsRepository } from './repositories/sets'
import { VariantsRepository } from './repositories/variants'
import { RegistryRepository } from './repositories/registry'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@AppModule({
  name: 'content',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IAcceptanceRepository>(AcceptanceRepositoryType).to(AcceptanceRepository)
      bind<IContentRepository>(ContentRepositoryType).to(ContentRepository)
      bind<IRegistryRepository>(RegistryRepositoryType).to(RegistryRepository)
      bind<ICommentRepository>(CommentRepositoryType).to(CommentRepository)
      bind<INodesRepository>(NodesRepositoryType).to(NodesRepository)
      bind<ISetItemsRepository>(SetItemsRepositoryType).to(SetItemsRepository)
      bind<ISetsRepository>(SetsRepositoryType).to(SetsRepository)
      bind<IFeedsRepository>(FeedsRepositoryType).to(FeedsRepository)
      bind<IFeedEntriesRepository>(FeedEntriesRepositoryType).to(FeedEntriesRepository)
      bind<IVariantsRepository>(VariantsRepositoryType).to(VariantsRepository)
      bind<IVariantStatusService>(VariantStatusServiceType).toDynamicValue((context: Interfaces.Context) => {
        return new VariantStatusService(
          context.container.get<IVariantsRepository>(VariantsRepositoryType),
          context.container.get<IModal>(ModalType)
        )
      })
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)
  },
  routes: routesFactory
})
export class ContentModule extends Module {
}
