// Copyright © 2021 Move Closer

import { AnyModule, DashmixTheme, EmbedContent, Related, RelatedType } from '@d24/modules'
import { AnyObject, ConjunctionOperator, ICollection, IDateTime, IModel, MagicModel } from '@movecloser/front-core'

import { HasIdentifier, Identifiable, Identifier } from '@/shared/contracts/data'

import { IOwner, OwnerData } from '@module/root/contracts/models'

import { ContentEditPayload, VariantUpdatePayload } from '../contracts/data'
import { Node } from '../models/node'
import { Sources, VariantLabel } from '../maps/variant'

export interface AcceptanceData extends HasIdentifier {
  asIs: VariantData
  acceptedAt: string | null
  acceptedBy: AuthorModel | null
  author: AuthorModel
  content: ContentData['id']
  contentType: string
  editor: AuthorModel
  lockedAt: string
  publishedAt: string
  status: ContentStatus
  title: string
  toBe: VariantData
  updatedAt: string
}

export type AcceptanceModel = MagicModel<AcceptanceData, IAcceptance>

export enum AcceptanceStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Published = 'published',
  Rejected = 'rejected'
}

export interface AdsAddonData {
  autoAds: boolean
}

export interface AdUnitData extends HasIdentifier {
  fullPath?: string
  name: string
  sizes: AdUnitSize[]
}

export interface AdUnitSize {
  width: number
  height: number
  fullDisplayString: string
}

export type AdUnitModel = MagicModel<AdUnitData>

export interface Attachments {
  id: Identifier

  [key: string]: unknown
}

export interface ArticleData extends Attachments {
  title: string
}

export interface AuthorAddonData extends Attachments {
  fullName: string
  source?: Sources
}

export type AuthorData = OwnerData
export type AuthorModel = MagicModel<AuthorData, IAuthor>

export interface Badge {
  id?: Identifier
  name: string
}

export interface CommentData extends HasIdentifier {
  content: string
  creator: AuthorModel
  createdAt: string
  meta: Meta
  owner: {
    id: Identifier
    title: string
    url: string
    site: SiteData
  }
  legacyName: string
  status: CommentStatus
}

export type CommentModel = MagicModel<CommentData>

export enum CommentStatus {
  Accepted = 'accepted',
  InAcceptance = 'in-acceptance',
  Rejected = 'rejected',
  Spam = 'spam'
}

export interface RegistryData extends HasIdentifier {
  address: string
  publishable_id: number
  publishable_type: string
  redirection_target: string
  redirection_type: string
  site_id: number
  content: ContentModel
}

export type RegistryModel = MagicModel<RegistryData, IRegistry>

export interface ContentData extends HasIdentifier {
  children?: ContentModel[]
  childrenCount?: number
  author: AuthorModel
  createdAt: string
  editors: AuthorModel
  parent: ParentData
  properties: Properties
  status: ContentStatus
  slug: string
  site: SiteData
  title: string
  type: ContentType
  updatedAt: string
  url: string | null
  variants?: SimpleVariantModel[]
  variantsCount: VariantsCounts
  order: number
}

export type ContentModel = MagicModel<ContentData, IContent>

export interface ContentInfoData {
  articleCount: number
  articleDraftCount: number
  articlesStatusCount: AnyObject[]
  latestArticleCount: number
}

export enum ContentStatus {
  Accepted = 'accepted',
  Archived = 'archived',
  Draft = 'draft',
  InAcceptance = 'in-acceptance',
  Published = 'published',
  Rejected = 'rejected',
  Unpublished = 'unpublished'
}

export const ContentStatusBadge = {
  [ContentStatus.Accepted]: DashmixTheme.Warning,
  [ContentStatus.Archived]: DashmixTheme.Danger,
  [ContentStatus.Draft]: DashmixTheme.Dark,
  [ContentStatus.InAcceptance]: DashmixTheme.Warning,
  [ContentStatus.Published]: DashmixTheme.Success,
  [ContentStatus.Rejected]: DashmixTheme.Danger,
  [ContentStatus.Unpublished]: DashmixTheme.Danger
}

export enum ContentType {
  Article = 'article',
  LiveArticle = 'live_article',
  Page = 'page',
  SmartPage = 'smart_page',
  SmartLink = 'smart_link'
}

export interface CoverData {
  image?: Related<RelatedType.File, Identifier>
  gallery?: Related<RelatedType.Gallery, Identifier> | null
  video?: Video
  extraDistinction?: boolean
  caption?: string
  alt?: string
  imageAuthor?: string
  override?: boolean
  hideDescription?: boolean
}

export interface DelayedDates {
  from?: string
  to?: string
}

export interface IAcceptance extends IModel<AcceptanceData> {
  isAcceptable (): boolean

  isRejectable (): boolean
}

export type IAuthor = IOwner

export interface IComment extends IModel<CommentData> {
  isAcceptable (): boolean

  isRejectable (): boolean
}

export interface IRegistry extends IModel<RegistryData> {
  identifier (): number
}

export interface IContent extends IModel<ContentData> {
  authorName (): string

  fullSlug (): string

  isLive (): boolean

  propertyByKey (key: string): any

  toEditPayload (): ContentEditPayload

  variantsInTotal (): number
}

export interface ISet extends Identifiable, IModel<SetData> {
  authorName (): string

  getItemCount (): number
}

export type ISimpleContent = IModel<SimpleContentData>

export interface ISimpleVariant extends IModel<SimpleVariantData> {
  isEditor (user: Identifier | null): boolean
}

export interface ISneakPeek extends IModel<SneakPeekData> {
  toSetItem (): any
}

export interface IVariant extends IModel<VariantData> {
  changeAuthor (newAuthor: AuthorModel): void

  getProperty<T> (propertyKey: string): T | null

  setProperty<T> (propertyKey: string, payload: T): void

  isAuthor (user: Identifier | null): boolean

  isDraft (): boolean

  isEditable (user: Identifier | null): boolean

  isEditor (user: Identifier | null): boolean

  isLive (): boolean

  isRemovable (user: Identifier | null): boolean

  isSmart (): boolean

  toSimpleVariant (): SimpleVariantModel

  toUpdatePayload (): VariantUpdatePayload
}

export interface LiveFeedAddonData {
  feedId: string | undefined
  reverseOrder: boolean | undefined
}

export interface Meta extends Record<MetaKeys, any> {
  keywords: string
  description: string
  ogDescription: string
  ogImage: Attachments
  ogTitle: string
  robots: RobotsValues
  title: string
  twitterDescription: string
  twitterImage: Attachments
  twitterImageAlt: string
  twitterTitle: string
}

export enum MetaKeys {
  Keywords = 'keywords',
  Description = 'description',
  ogDescription = 'ogDescription',
  OgImage = 'ogImage',
  OgTitle = 'ogTitle',
  Title = 'title',
  TwitterDescription = 'twitterDescription',
  TwitterImage = 'twitterImage',
  TwitterImageAlt = 'TwitterImageAlt',
  TwitterTitle = 'twitterTitle'
}

export interface NodeData {
  id: string
  name: string
  slug?: string
  title?: string
  hideInNav?: boolean
  nodes: ICollection<NodeModel>
}

export type NodeModel = MagicModel<NodeData, Node>

export interface OverridePublishDate {
  date: string
}

export interface Properties {
  [key: string]: boolean | string | unknown[]
}

export interface ParentData extends HasIdentifier {
  id: number
  name: string
  slug: string
  parent: ParentData | null
}

export interface Preferences {
  [key: string]: any
}

export interface PremiumAttachmentsData extends Attachments {
  icon?: any // FIXME: Cleanup Addons contracts.
  filename: string
}

export enum RobotsValues {
  FullLimitation = 'noindex, nofollow',
  NoLimitations = 'index, follow',
  OnlyIndex = 'index, nofollow',
  OnlyFollow = 'noindex, follow'
}

export interface SetData extends HasIdentifier {
  editor: AuthorModel
  createdAt?: string
  childrenCount: number
  properties: SetProperties
  strategy: SetStrategy
  title: string
  type: SetType
  updatedAt?: string
}

export interface SetItem {
  id: number
  newlyAdded?: boolean
  position: number
  travelDistance?: number
}

export type SetModel = MagicModel<SetData, ISet>

export interface SetItemsDate {
  setItemsFrom: string
  setItemsTo: string
}

export interface SetProperties {
  autoDates: boolean
  conjunction: ConjunctionOperator
  src: SetSource[]
  locked: SneakPeekModel[]
  setItemsDate: SetItemsDate
  skipPremium?: boolean | null
  generateHTML?: boolean | null
  showOlderThanHours?: number | null
}

export interface RelatedAddonData extends Attachments {
  name: string
}

export interface RegistryPayload {
  data: AnyObject
  meta: AnyObject
  related: AnyObject
}

export interface SetSource {
  type: SourceType | string
  // Dla tagów lub labels - slug, dla autorów - id
  value: string
  label?: string
}

export enum SetStrategy {
  Related = 'related',
  Automatic = 'automatic',
  Manual = 'manual'
}

export enum SetType {
  Articles = 'articles'
}

export interface SimpleContentData extends HasIdentifier {
  order: number
  slug: string
  title: string
}

export type SimpleContentModel = MagicModel<SimpleContentData, ISimpleContent>

export interface SimpleVariantData extends HasIdentifier {
  addons: string[]
  author: AuthorModel
  createdAt: string
  editor: AuthorModel
  labels: Label[]
  name: string
  status: ContentStatus
  updatedAt: string
}

export interface Label extends HasIdentifier {
  name: string
  slug: string
}

export type SimpleVariantModel = MagicModel<SimpleVariantData, ISimpleVariant>

export interface SiteData {
  address: string
  domain: string
  name: string
}

export interface SneakPeekData extends HasIdentifier {
  author: AuthorModel
  fullUrl?: string
  parent: ParentData | null
  publicationDate: string
  properties: AnyObject
  slug: string
  title: string
  position: number
  locked: boolean

  [key: string]: any
}

export type SneakPeekModel = MagicModel<SneakPeekData, ISneakPeek>

export enum SourceType {
  Author = 'author',
  Category = 'category',
  CategoryRecurrent = 'categoryRecurrent',
  Label = 'label',
  Tag = 'tag'
}

export interface FeedData extends HasIdentifier {
  description: string
  entriesCount: number
  status: FeedStatus
  title: string
  lock: boolean
  lockedBy: Identifier | null
}

export enum FeedStatus {
  Ended = 'ended',
  Live = 'live',
  New = 'new'
}

export const FeedStatusBadge: Record<FeedStatus, DashmixTheme> = {
  [FeedStatus.Ended]: DashmixTheme.Info,
  [FeedStatus.New]: DashmixTheme.Dark,
  [FeedStatus.Live]: DashmixTheme.Primary
} as const

export interface IFeed extends IModel<FeedData> {
  selectable (): boolean

  isEnded (): boolean

  isDraft (): boolean

  isLive (): boolean

  isLocked (): boolean
}

export type FeedModel = MagicModel<FeedData, IFeed>

export interface FeedEntryData extends HasIdentifier {
  content: string
  createdAt: string
  deletedAt?: string
  image?: Related<RelatedType.File>
  gallery?: Related<RelatedType.Gallery>
  embed?: EmbedContent
}

export type FeedEntryModel = MagicModel<FeedEntryData>

export interface VariantBadge {
  id: Identifier
  name?: VariantLabel | string
  color?: string
}

export interface VariantData extends HasIdentifier {
  author: AuthorModel
  addons: string[]
  content: ContentData['id']
  createdAt: IDateTime
  editor: AuthorModel | null
  grid: {}
  lockedAt: string
  name: string | null
  modules: AnyModule[]
  preferences: Preferences
  properties: object | null
  reference: VariantData['id'] | null
  status: ContentStatus
  title: string
  updatedAt: IDateTime
  url: string | null
}

export interface VariantsCounts {
  [key: string]: number
}

export type VariantModel = MagicModel<VariantData, IVariant>

export interface Video {
  file?: Related
  url: string
  onNetworkUrl: string
  playOnTile: boolean
}
