// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '@module/auth/contracts/permissions'

import Acceptance from './views/Acceptance.vue'
import AcceptanceList from './views/AcceptanceList.vue'
import ArticlesList from './views/ArticlesList.vue'
import CommentsAcceptanceList from '@module/content/views/CommentsAcceptanceList.vue'
import CommentsList from './views/CommentsList.vue'
import CreateArticle from './views/CreateArticle.vue'
import CreatePage from './views/CreatePage.vue'
import CreateSet from './views/CreateSet.vue'
import EditArticleContent from './views/EditArticleContent.vue'
import EditArticleBasics from './views/EditArticleBasics.vue'
import EditFeed from './views/feeds/EditFeed.vue'
import EditSet from './views/EditSet.vue'
import EditPageContent from './views/EditPageContent.vue'
import EditPageBasics from './views/EditPageBasics.vue'
import FeedsList from './views/feeds/FeedsList.vue'
import PagesList from './views/PagesList.vue'
import SetsList from './views/SetsList.vue'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/content/acceptance/:contentType/:id',
      name: 'acceptance.show',
      component: Acceptance,
      meta: {
        permissions: [
          Permission.AcceptContents
        ]
      }
    },
    {
      path: '/content/acceptance/:contentType',
      name: 'acceptance.list',
      component: AcceptanceList,
      meta: {
        permissions: [
          Permission.AcceptContents
        ]
      }
    },
    {
      path: '/content/articles',
      name: 'articles.list',
      component: ArticlesList,
      meta: {
        permissions: [
          Permission.ViewContents
        ]
      }
    },
    {
      path: '/content/articles/create',
      name: 'articles.create',
      component: CreateArticle,
      meta: {
        permissions: [
          Permission.CreateContents
        ]
      }
    },
    {
      path: '/content/commentsList',
      name: 'comments.list',
      component: CommentsList,
      meta: {
        permissions: [
          Permission.ViewContentsComments
        ]
      }
    },
    {
      path: '/content/commentsAcceptance',
      name: 'comments.acceptance.list',
      component: CommentsAcceptanceList,
      meta: {
        permissions: [
          Permission.ViewContentsComments
        ]
      }
    },
    {
      path: '/content/articles/:id/basics',
      name: 'articles.basics',
      component: EditArticleBasics,
      meta: {
        permissions: [
          Permission.EditContents
        ]
      }
    },
    {
      path: '/content/articles/:id/content',
      name: 'articles.content',
      component: EditArticleContent,
      meta: {
        permissions: [
          Permission.EditContents
        ]
      }
    },
    {
      path: '/content/feeds',
      name: 'feeds.list',
      component: FeedsList,
      permissions: [
        Permission.ViewContents
      ]
    },
    {
      path: '/content/feeds/:id',
      name: 'feeds.edit',
      component: EditFeed,
      permissions: [
        Permission.EditContents
      ]
    },
    {
      path: '/content/pages/:id/basics',
      name: 'pages.basics',
      component: EditPageBasics,
      meta: {
        permissions: [
          Permission.EditContents
        ]
      }
    },
    {
      path: '/content/pages/:id/content',
      name: 'pages.content',
      component: EditPageContent,
      meta: {
        permissions: [
          Permission.EditContents
        ]
      }
    },
    {
      path: '/content/pages/create',
      name: 'pages.create',
      component: CreatePage,
      meta: {
        permissions: [
          Permission.CreateContents
        ]
      }
    },
    {
      path: '/content/pages',
      name: 'pages.list',
      component: PagesList,
      meta: {
        permissions: [
          Permission.ViewContents
        ]
      }
    },
    {
      path: '/content/sets/:id/edit',
      name: 'sets.edit',
      component: EditSet,
      meta: {
        permissions: [
          Permission.EditSets
        ]
      }
    },
    {
      path: '/content/sets/create',
      name: 'sets.create',
      component: CreateSet,
      meta: {
        permissions: [
          Permission.CreateSets
        ]
      }
    },
    {
      path: '/content/sets',
      name: 'sets.list',
      component: SetsList,
      meta: {
        permissions: [
          Permission.ViewSets
        ]
      }
    },
    {
      path: '/content',
      redirect: '/content/articles'
    }
  ]
}
