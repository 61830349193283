// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'
import { authorAdapterMap } from '@module/content/models/author.adapter'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const sneakPeakAdapterMap: MappingConfig = {
  author: {
    type: MappingTypes.Adapter,
    map: authorAdapterMap,
    value: 'author'
  },
  fullUrl: 'fullUrl',
  id: 'id',
  parent: 'parent',
  publicationDate: 'publicationDate',
  slug: 'slug',
  title: {
    type: MappingTypes.Function,
    value: item => {
      return item.title || item.slug
    }
  }
}
