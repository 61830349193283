
















































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import {
  DashmixIconName,
  Picker,
  SizeMap,
  RelatedType,
  DashmixTheme,
  Related,
  DashmixSelectItem
} from '@d24/modules'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '@plugin/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'
import { Figure } from '@component/Figure/Figure'
import { FormCheckbox, FormInput } from '@component/form'
import { FormText } from '@component/form/TextArea'
import { FormSelect } from '@component/form/Select'
import { FormTypeahead } from '@component/FromTypeahead/FormTypeahead'
import { Hint } from '@component/Typeahead'
import { Identifier } from '@/shared/contracts/data'

import { DictionaryRepositoryType, IDictionaryRepository } from '@module/root/contracts/repositories'

import { AuthorTypes, SimpleUser } from '../contracts/models'
import { AuthorPayload } from '../contracts/data'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AuthorForm',
  components: { Figure, FormTypeahead, FormSelect, FormCheckbox, FormText, FormInput }
})
export class AuthorForm extends Vue {
  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: false })
  public isSaving!: boolean

  @PropSync('formData', { type: Object, required: true })
  public payload!: AuthorPayload

  @Inject(DictionaryRepositoryType)
  private dictionaryRepository!: IDictionaryRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  @Inject(ModalType)
  protected modalConnector!: IModal

  public readonly Icons = DashmixIconName

  public isLoading: boolean = false
  public options: Hint[] = []
  public themes = DashmixTheme
  public types: DashmixSelectItem[] = Object.values(AuthorTypes).map(type => {
    return {
      value: type,
      label: `${this.$t('authors.form.types.' + type)}`
    }
  })

  public get fullName () {
    return this.payload.firstName + this.payload.lastName
  }

  public get typeaheadOption (): Hint[] {
    if (this.payload.user) {
      return [{
        value: this.payload.user.id,
        label: this.getUserName(this.payload.user)
      }]
    }
    return []
  }

  created () {
    this.loadOptions()
  }

  public deleteAvatar () {
    this.$emit('avatarChosen', null)
  }

  public loadOptions (searchParams?: string) {
    this.isLoading = true
    this.dictionaryRepository.loadUsersDictionary({ q: searchParams || '' })
      .then(collection => {
        this.options = [...collection].map(option => {
          return {
            value: option.id,
            label: option.fullName
          }
        })
      }
      ).catch(error => console.log(error))
      .finally(() => {
        this.isLoading = false
      })
  }

  public onClear () {
    this.loadOptions()
  }

  public onDelete () {
    this.payload.user = null
  }

  public onSelect (selectedHint: Hint) {
    this.payload.user = { avatar: null, id: selectedHint.value as Identifier, fullName: selectedHint.label }
  }

  public onSearch (searchedParams: string) {
    this.loadOptions(searchedParams)
  }

  public openPicker () {
    let selected
    if (this.payload.avatar && this.payload.avatar.id) {
      selected = {
        value: this.payload.avatar.id,
        type: RelatedType.File
      }
    }
    this.modalConnector.open(Picker.Media, {
      config: { type: 'photo' },
      onClose: () => this.modalConnector.close(),
      onSelection: this.setAvatar,
      selected: selected
    }, { size: SizeMap.XLarge })
  }

  protected getUserName (user: SimpleUser) {
    if (user.fullName) {
      return user.fullName
    }

    return `${user.firstName} ${user.lastName}`
  }

  protected async setAvatar (photoData: Related<RelatedType.File, Identifier>) {
    const file: AnyObject = await this.relatedService.describe({ type: RelatedType.File, value: photoData.value })

    this.$emit('avatarChosen', { id: file.id, url: file.url })
  }
}

export default AuthorForm
