// Copyright © 2022 Move Closer

import {
  AnyModule,
  ModuleDriver,
  moduleDriverUiComponentRegistry,
  PaginationModule,
  Related,
  RelatedType
} from '@d24/modules'
import { VueConstructor } from 'vue'

interface ModuleConstructor extends VueConstructor {
  resolveArticlesCount?: (module: AnyModule, list: Related<RelatedType.Set>) => number
}

type ModulesDecorator = (modules: AnyModule[]) => AnyModule[]

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function recalculatePaginationPerPage (modules: AnyModule[]): AnyModule[] {
  // Let's check if there's a Pagination module required to process next steps, there's no override provided
  // and the subject is specified.
  const paginationModule = modules.find(m => m.driver === ModuleDriver.Pagination) as PaginationModule
  if (!paginationModule || paginationModule.content.override || paginationModule.content.list === null) {
    return modules
  }

  // Let's filter out modules that don't take part in pagination.
  const modulesToSumup: AnyModule[] = modules.filter(m => [ModuleDriver.ArticlesList, ModuleDriver.TeaserBox, ModuleDriver.Tile].includes(m.driver) && m.isVisible)
  if (!modulesToSumup.length) {
    return modules
  }

  // Now we need to pass through all of interesting modules & using their constructors discover how many articles
  // should be reserved for each.
  const interestedList: Related<RelatedType.Set> = paginationModule.content.list
  const articlesCount: number = modulesToSumup.reduce<number>((count: number, m: AnyModule) => {
    const constructor: ModuleConstructor = moduleDriverUiComponentRegistry[m.driver as ModuleDriver]
    if (typeof constructor.resolveArticlesCount === 'function') {
      try {
        count += constructor.resolveArticlesCount(m, interestedList)
      } catch (e) {
        console.debug(e.message)
      }
    }
    return count
  }, 0)

  // The last step is to set reduced value inside pagination module.
  const paginationIndex: number = modules.indexOf(paginationModule)
  modules[paginationIndex].content.perPage = articlesCount
  return modules
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
const decorators: ModulesDecorator [] = [recalculatePaginationPerPage]

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function decorateModules (modules: AnyModule[]): AnyModule[] {
  for (const decorator of decorators) {
    modules = decorator(modules)
  }

  return modules
}
