


















































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, TableHead, TableRowAction, TableRowActionEvent } from '@d24/modules'
import { ICollection, IMeta, IModal, ModalType } from '@movecloser/front-core'
import { Modals } from '@/config/modals'
import { Route } from 'vue-router'
import { debounce } from 'lodash'

import { DropdownActions } from '@/shared/contracts/content'
import { EmptyTable } from '@component/InteractiveTable/partials/EmptyTable'
import { Filters } from '@component/Filters'
import { Identifiable, Identifier } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { Query } from '@/shared/contracts/query'

import {
  DirectoryModel, DirectoryRepositoryType, FileModel,
  FileRepositoryType, IDirectoryRepository, IFileRepository, MediaTableRowElement, mediaTypes
} from '../contracts'
import { MediaActions, mediaTableHead } from '../maps/media'
import { MediaTableRow } from '../components/MediaTableRow.vue'
import { SelectedFilesList } from './SelectedFilesList.vue'
import { TilesGrid } from '../components/TilesGrid.vue'
import { fileFiltersConfig } from '../models/file.filter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component<MediaView>({
  name: 'MediaView',
  components: { EmptyTable, Filters, MediaTableRow, SelectedFilesList, TilesGrid },
  mounted () {
    this.loadList(this.queryParams)
  }
})
export class MediaView extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public abandonedMode!: boolean

  @Prop({ type: String, required: false, default: mediaTypes.File })
  public allowedMediaType!: string

  @Prop({ type: Boolean, required: false, default: false })
  public actionColumn!: boolean

  @Prop({ type: Object, required: true })
  public actions!: DropdownActions

  @Prop({ type: String, required: false, default: '' })
  public className!: string

  @PropSync('currentDirectoryModel', { type: Object, required: false })
  public currentDirectory!: DirectoryModel | null

  @Prop({ type: Boolean, required: false, default: true })
  public isEditable!: boolean

  @PropSync('loading', { type: Boolean, required: false, default: false })
  public isLoading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isMultiple!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isSelectable!: boolean

  @Prop({ type: Number, required: false })
  public numberOfRows!: number

  @Prop({ type: Array, required: true })
  public rowActions!: TableRowAction[]

  // Route variable for when routeSync is off
  @PropSync('routeModel', { type: Object, required: false })
  public routeSubstitute!: Route

  // Defines whether route params should be synced with url
  @Prop({ type: Boolean, required: true })
  public routeSync!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public searchable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public selectColumn!: boolean

  @Prop({ type: Array, required: false })
  public selectedIds?: Identifier[]

  @Prop({ type: Array, required: false })
  public selected?: FileModel[]

  @Prop({ type: Boolean, required: false, default: true })
  public tileMode!: boolean

  @Inject(DirectoryRepositoryType)
  protected directoryRepository!: IDirectoryRepository

  @Inject(FileRepositoryType)
  protected fileRepository!: IFileRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  public directoriesData: MediaTableRowElement[] = []
  public files: Map<string, FileModel> = new Map()
  public filesData: MediaTableRowElement[] = []
  public icons = DashmixIconName
  public itemsTotal: number = 0
  public filtersConfig = fileFiltersConfig
  public pagesTotal: number = 1
  public rowComponent = MediaTableRow
  public selectedRows: string[] = []
  public tableHead: TableHead = mediaTableHead

  public get currentPage (): number {
    if (Object.prototype.hasOwnProperty.call(this.queryParams, 'page') && this.queryParams.page != null) {
      return parseInt(this.queryParams.page as string, 10)
    }

    return 1
  }

  public set currentPage (page: number) {
    this.setQuery({
      ...this.queryParams,
      page: page.toString()
    })
  }

  public get filesList () {
    return [...this.files.values()]
  }

  public get isBackButtonHidden (): boolean {
    return !this.currentDirectory?.parent?.id
  }

  public get perPage (): number {
    if (Object.prototype.hasOwnProperty.call(this.queryParams, 'perPage') && this.queryParams.perPage != null) {
      return Number(this.queryParams.perPage)
    }

    return this.numberOfRows ? this.numberOfRows : 50
  }

  public set perPage (perPage: number) {
    this.setQuery({
      ...this.queryParams,
      perPage: perPage.toString(),
      page: '1'
    })
  }

  public get q (): string {
    if (Object.prototype.hasOwnProperty.call(this.queryParams, 'q') && this.queryParams.q) {
      return this.queryParams.q as string
    }

    return ''
  }

  public set q (q: string) {
    if (q) {
      this.setQuery({
        ...this.queryParams,
        q: q.toString()
      })
    } else {
      this.setQuery({})
    }
  }

  public get queryParams (): Query {
    return this.routeSync ? this.$route.query : this.routeSubstitute.query
  }

  public get routeParams (): Query {
    return this.routeSync ? this.$route.params : this.routeSubstitute.params
  }

  public get searchMode (): boolean {
    return !!this.queryParams?.q
  }

  public get tableData (): MediaTableRowElement[] {
    return [...this.directoriesData, ...this.filesData]
  }

  public doAction<Model extends Identifiable = Identifiable> (event: TableRowActionEvent<Model>): void {
    const { action, data } = event

    if (this.actions[action].confirmation) {
      this.modalConnector.open(Modals.Confirm, {
        content: {
          ...this.actions[action].confirmation,
          contentTitle: data.displayName()
        },
        onConfirm: () => {
          this.actions[action].callback(data)
          this.modalConnector.close()
        }
      })
    } else {
      this.actions[action].callback(data)
    }
  }

  public doBulkActions (actionKey: string, selected: string[]) {
    const bulkCallback = () => {
      const promises: Promise<void>[] = []

      for (const id of selected) {
        const result = this.actions[actionKey].callback(
          this.currentDirectory?.files.find(
            model => model.id === parseInt(id)
          )
        )

        if (result instanceof Promise) {
          promises.push(result)
        }
      }

      if (promises.length) {
        Promise.all(promises).finally(() => {
          this.isLoading = false
        })
      } else {
        this.isLoading = false
      }
    }

    if (this.actions[actionKey].confirmation) {
      this.modalConnector.open(Modals.Confirm, {
        content: {
          ...this.actions[actionKey].confirmation,
          contentTitle: this.$t('atoms.elements', { count: selected.length })
        },
        onConfirm: () => {
          bulkCallback()
          this.modalConnector.close()
        }
      })
    } else {
      this.actions[actionKey].callback(selected)
      this.isLoading = false
      this.selectedRows.splice(0)
    }
  }

  public goToParentDirectory (): void {
    this.isLoading = true
    if (this.currentDirectory && this.currentDirectory.parent) {
      this.actions[MediaActions.OpenDirectory].callback(this.currentDirectory.parent)
    }
  }

  public loadFile (id: Identifier) {
    this.fileRepository.loadFile(id)
      .then(model => {
        this.files.set(`${id}`, model)
        this.updateSelection(this.files)
      }).catch(error => {
        console.log(error)
      })
  }

  /**
   * Load list based on query, either loads search or directory
   * @param query
   */
  public loadList (query: Query): void {
    this.isLoading = true

    let promise: Promise<ICollection<unknown>>
    if (this.abandonedMode) {
      promise = this.fileRepository.loadAbandonedList({ ...query })
    } else {
      promise = query.q
        ? this.directoryRepository.search({ ...query, with: 'directories', type: this.allowedMediaType })
        : this.directoryRepository.loadDirectory(parseInt(this.routeParams.id as string), { ...query, type: this.allowedMediaType })
    }

    promise.then(data => {
      this.isLoading = false

      const meta: IMeta = data.meta || {}
      let model: DirectoryModel

      if (this.abandonedMode) {
        model = { files: [...data], directories: [] } as unknown as DirectoryModel

        this.itemsTotal = Object.keys(meta).length > 0 ? meta.total : 0
        this.pagesTotal = Object.keys(meta).length > 0 ? Math.ceil(meta.total / meta.per_page) : 1
      } else {
        model = data[0] as DirectoryModel

        this.itemsTotal = Object.keys(meta).length > 0 ? meta.total : model.childrenCount()
        this.pagesTotal = Object.keys(meta).length > 0 ? Math.ceil(meta.total / meta.per_page) : 1
      }

      this.selectedRows.splice(0)
      this.prepareTableData(model)
      this.currentDirectory = model
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  public onSelection (selected: any): void {
    this.selectedRows = selected.selected
  }

  public prepareTableData (currentDirectory: DirectoryModel | null): void {
    this.directoriesData.splice(0)
    this.filesData.splice(0)

    if (currentDirectory) {
      this.directoriesData = currentDirectory.directories.map(model => {
        return {
          id: `d${model.id}`,
          selectable: false,
          selected: false,
          data: model
        }
      })

      this.filesData = currentDirectory.files.map(model => {
        return {
          id: `${model.id}`,
          selectable: !model.pending,
          selected: this.selectedRows.includes(model.id.toString()),
          data: model
        }
      })
    }
  }

  public search (payload: string) {
    this.searchDebounce(payload)
  }

  public searchDebounce = debounce(this.updateSearch.bind(this), 500)

  public setQuery (newQuery: Query): void {
    if (JSON.stringify(this.queryParams) === JSON.stringify(newQuery)) {
      return
    }

    if (this.routeSync) {
      this.$router.push({
        name: this.$route.name ? this.$route.name : '',
        params: this.$route.params,
        query: newQuery
      })
    } else {
      this.routeSubstitute.query = newQuery
    }
  }

  public updateCurrentPage (payload: number) {
    this.currentPage = payload
  }

  public updatePerPage (payload: number) {
    this.perPage = payload
  }

  public updateSearch (payload: string) {
    this.q = payload
  }

  public updateSelection (files: Map<string, FileModel>) {
    this.files = new Map(files)

    this.$emit('select', this.filesList)
  }

  @Watch('currentDirectoryModel')
  public onCurrentDirectoryChange (dir: DirectoryModel): void {
    this.prepareTableData(dir)
  }

  @Watch('queryParams', { deep: true })
  public onPageChange (query: Query): void {
    this.loadList(query)
  }

  @Watch('tileMode')
  public onTileModeChange (): void {
    this.selectedRows.splice(0)
  }

  @Watch('selectedIds')
  public setSelected (selectedIds?: Identifier[]) {
    if (selectedIds) {
      selectedIds.forEach(id => {
        this.loadFile(id)
      })
    }
  }

  @Watch('selected')
  public updateSelected (selected?: FileModel[]) {
    if (selected) {
      this.files = new Map()
      selected.forEach(file => {
        this.files.set(`${file.id}`, file)
      })
    }
  }
}

export default MediaView
