



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@d24/modules'
import { Collection, ICollection, IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '@plugin/inversify'
import { Modals } from '@/config/modals'

import { ScrollHandle } from '@/shared/directives/scroll'
import { Identifier } from '@/shared/contracts/data'

import { AddSetItemIntention } from '../intentions/AddSetItemIntention'
import {
  AddSetItemModalPayload,
  SneakPeekModel,
  ISetItemsRepository,
  SetItemsRepositoryType,
  SetModel
} from '../contracts'
import { Author } from '../models/author'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'
import { SneakPeek } from '../models/sneak-peek'
import SetDraggableList from './SetDraggableList.vue'

@Component<SetSidebar>({
  name: 'SetSidebar',
  components: { SetDraggableList },
  directives: { ScrollHandle },

  mounted () {
    this.loadSetItems()
    this.count = this.set.childrenCount
  }
})
export class SetSidebar extends Vue {
  @Prop({ type: Object, required: true })
  public set!: SetModel

  @Prop({ type: Number, required: false })
  public siteId?: Identifier

  @Prop({ type: Boolean, required: false, default: true })
  public isEditable?: boolean

  @Prop({ type: Array, required: false, default: () => ([]) })
  private lockedItems!: SneakPeekModel[]

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(SetItemsRepositoryType)
  private setItemsRepository!: ISetItemsRepository

  public buttonTheme = DashmixTheme
  public count = 0
  public Icons = DashmixIconName
  public isLoading: boolean = false
  private items: SneakPeekModel[] = []
  private numOfPages: number = 0
  private page: number = 1
  private perPage: number = 10

  public get filteredList (): SneakPeekModel[] {
    if (this.items.length > 0 || typeof this.items !== 'undefined') {
      return this.items.map((item) => ({
        ...item,
        publicationDate: item.publicationDate ?? '',
        locked: this.lockedItemsIds.includes(item.id)
      })).sort((a, b) => a.position - b.position)
    } else {
      return []
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/adjacent-overload-signatures
  public set filteredList (collection: SneakPeekModel[]) { }

  public get lockedItemsIds (): Identifier[] {
    return this.lockedItems.map((item) => item.item.id)
  }

  public handleItemAdded () {
    const payload: AddSetItemModalPayload = {
      onClose: () => this.modalConnector.close(),
      onConfirm: this.addItem
    }

    this.modalConnector.open(Modals.AddSetItem, payload, { closableWithOutsideClick: true })
  }

  public async handleReorder ({ newIndex, oldIndex }: { newIndex: number; oldIndex: number }) {
    if (newIndex === oldIndex) {
      return
    }

    this.isLoading = true

    const intention = new ChangeItemsOrderIntention({
      id: this.filteredList[oldIndex].id,
      position: newIndex + 1
    })

    try {
      await this.setItemsRepository
        .changeOrder(this.set.id, intention).then(() => {
          const lockedItem: any = {
            position: newIndex,
            item: this.items[this.items.findIndex(item => +item.position === oldIndex)]
          }
          this.lockedItems.push(lockedItem)

          this.items[this.items.findIndex(item => +item.position === oldIndex)].set('position', newIndex)
          this.moveElement(this.items, oldIndex, newIndex)
          this.items = this.withPosition(this.items)
        })
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public async handleMovingToPosition (args: { newIndex: number; oldIndex: number }): Promise<void> {
    const { newIndex, oldIndex } = args
    if (newIndex === oldIndex) {
      return
    }

    this.isLoading = true

    const intention = new ChangeItemsOrderIntention({
      id: this.filteredList[oldIndex].id,
      position: newIndex + 1
    })
    try {
      await this.setItemsRepository
        .changeOrder(this.set.id, intention).then(() => {
          const lockedItem: any = {
            position: newIndex,
            item: this.items[this.items.findIndex(item => +item.position === oldIndex)]
          }
          this.lockedItems.push(lockedItem)
          this.items[this.items.findIndex(item => +item.position === oldIndex)].set('position', newIndex)
          this.moveElement(this.items, oldIndex, newIndex)
          this.items = this.withPosition(this.items)
        })
    } catch (error) {
      console.warn(error)
    } finally {
      this.isLoading = false
    }
  }

  public lockItem (itemId: Identifier): void {
    this.$emit('lockItem', itemId)
  }

  public unlockItem (itemId: Identifier): void {
    this.$emit('unlockItem', itemId)
  }

  protected async addItem (id: number) {
    this.page = 1
    try {
      const newItem = {
        id: id,
        position: 1
      }

      await this.setItemsRepository.add(this.set.id, new AddSetItemIntention(newItem))
      this.items = []
      this.count += 1
      this.loadSetItems(this.page, true)
    } catch (e) { console.error(e) }
    this.modalConnector.close()
  }

  protected onRemove (itemId: Identifier): void {
    this.page = Math.floor(this.items.findIndex(item => +item.id === itemId) / this.perPage)
    this.items.splice(this.page * this.perPage)
    this.page++
    this.count -= 1
    this.loadSetItems(this.page, this.page !== 1)
  }

  protected switchPage () {
    if (!this.isLoading && this.page < this.numOfPages) {
      this.page++
    }
  }

  protected moveElement (array: any[], from: number, to: number): void {
    array.splice(to, 0, array.splice(from, 1)[0])
  }

  protected loadSetItems (withPage?: number, paginated: boolean = false): void {
    this.isLoading = true
    this.setItemsRepository.loadCollection(this.set.id, { page: withPage || this.page, perPage: this.perPage }, this.siteId)
      .then(
        (setItems: ICollection<SneakPeekModel>) => {
          this.items = this.withPosition(setItems, paginated)
          this.numOfPages = Math.ceil(this.count / this.perPage)
        }
      ).catch((e) => console.log(e.toString()))
      .finally(() => {
        this.isLoading = false
      })
  }

  protected withPosition (collection: SneakPeekModel[], paginated: boolean = false) {
    if (paginated) {
      const upcomingList = new Collection<SneakPeekModel>([...collection].map((item: SneakPeekModel, index: number) => {
        return SneakPeek.hydrate(
          {
            ...item.toObject(),
            author: Author.hydrate(item.author.toObject()),
            position: this.filteredList.length > 0 ? (this.filteredList[this.filteredList.length - 1].position + 1) + index : index
          })
      }))
      this.items.push(...upcomingList)

      return [...this.items]
    }

    return new Collection<SneakPeekModel>(
      [...collection].map((item: SneakPeekModel, index: number) => {
        return SneakPeek.hydrate(
          {
            ...item.toObject(),
            author: Author.hydrate(item.author.toObject()),
            position: index
          })
      }))
  }

  @Watch('page')
  async loadMore (newPage: number) {
    this.loadSetItems(newPage, true)
  }

  @Watch('set')
  async load (set: SetModel) {
    this.loadSetItems(1, false)
    this.count = set.childrenCount
  }
}

export default SetSidebar
