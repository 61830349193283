


























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName } from '@d24/modules'
import { Fragment } from 'vue-fragment'

import { Inject } from '@/shared/plugins/inversify'

import { IUserAware, UserAware } from '@module/auth/shared/user-aware.mixin'

import { ContentModel, ContentStatusBadge, ContentType, Label, SimpleVariantModel } from '../contracts'
import { EditorsStack } from './EditorsStack.vue'
import { resolveVariantsToLoad } from '@module/content/helpers'

@Component({
  name: 'ArticlesTableRow',
  components: {
    EditorsStack,
    Fragment
  }
})
export class ArticlesTableRow extends Mixins<IUserAware>(UserAware) {
  @Prop({ type: Object, required: true })
  public data!: ContentModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  private ContentStatusBadge = ContentStatusBadge
  public ContentType = ContentType
  public Icons = DashmixIconName

  public get hasParent (): boolean {
    return !!this.data.parent
  }

  public get labels (): Label[] {
    if (!this.data.variants) {
      return []
    }

    const toLoad: SimpleVariantModel[] = resolveVariantsToLoad(this.data.variants, null)

    if (toLoad.length === 0) {
      return []
    }

    return toLoad[0].labels
  }

  public get parentId (): number|null {
    return this.hasParent ? this.data.parent.id : null
  }

  public get parentLabel (): string {
    return this.hasParent ? this.data.parent.name : ''
  }
}

export default ArticlesTableRow
